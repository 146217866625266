import React from "react";
import styled from "styled-components";

const Table = styled.table`
  margin-bottom: 80px;
  border: 1px solid #760F19;
  td {
    width: 100%;
    border: none;
    padding: 20px 10px;
    
  }
  td.first {
    width: 0%;
    background:#f1f1f1;
    
  }
  td.nopadding {
    padding: 0;
  }
  tbody > tr, tbody > tr > td  {
    border: 1px solid #760F19;
  }
  
  .nested-table {
    width: 100%;
    margin: 0;
    padding: 0;
    td.right {
      border-left: 1px solid #760F19;
    }
    td.left {
      border-right: 1px solid #760F19;
    }
  }
  
  @media (max-width: 670px){
    display: none;
  }
`;

const AltTable = styled.div`
    margin-bottom: 50px;
 @media (min-width: 671px){
    display: none;
  }
  
`;

const Headline = styled.h2`
    margin-top: 50px;
`;

const Headline2 = styled.h3`
    font-size: 20px;
    margin-bottom: 5px;
`;

export const Pricing = () => {
    return <div>
        <Headline>PREISE</Headline>
        <AltTable>
            <Headline2>Inklusivleistungen</Headline2>
            <ul>
                <li>personalisierte App inkl. Webshop</li>
                <li>500 Flyer</li>
                <li>Content Management & Support</li>
                <li>Benötigte Hardware in Form eines Mini-PC & Druckers</li>
            </ul>
            <Headline2>
                Einrichtung
            </Headline2>
            <p>Einmalig für initiales Aufsetzen, Hardware und Einrichtung 249€</p>
            <Headline2>
                Lizenz
            </Headline2>
            <p>
                monatlich 149€ oder jährlich 1490€.
            </p>
            <Headline2>
                Besonderheiten
            </Headline2>
            <ul>
                <li>
                    Monatlich kündbar
                </li>
                <li>
                    Betreuung & Support inklusive
                </li>
            </ul>
        </AltTable>
        <Table>
            <tbody>
            <tr>
                <td className={"first"}>
                    Inklusivleistungen
                </td>
                <td>
                    personalisierte App inkl. Webshop • 500 Flyer • Content Management & Support • Benötigte Hardware in Form eines Mini-PC & Druckers
                </td>
            </tr>
            <tr>
                <td className={"first"}>Einrichtung</td>
                <td>Einmalig für initiales Aufsetzen, Hardware und Einrichtung 249€</td>
            </tr>
            <tr>
                <td className={"first"}>Lizenz</td>
                <td className={"nopadding"}>
                    <table className={"nested-table"} width={"100%"}>
                        <tr>
                            <td style={{width: "50%"}}>
                                monatlich 149€
                            </td>
                            <td className="right" style={{width: "50%"}}>
                                oder jährlich 1490€
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td className={"first"}>
                    Besonderheiten
                </td>
                <td className={"nopadding"}>
                    <table className={"nested-table"} width={"100%"}>
                        <tr>
                            <td className={"left"} style={{width: "33%"}}>
                                Monatlich kündbar
                            </td>
                            <td className="right" style={{width: "33%"}}>Betreuung & Support inklusive</td>
                        </tr>
                    </table>
                </td>
            </tr>
            </tbody>
        </Table>
    </div>
};
