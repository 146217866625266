import React, {useRef} from "react"
import styled from "styled-components"
import { Button } from "./button"

const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  align-items: center;
  div {
    margin: 0 0px 40px;
  }
  input {
    max-width: 444px;
    width: 100%;
    height: 45px;
    line-height: 45px;
    border: 1px solid #760f19;
    border-radius: 8px;

    padding: 0 15px;
    margin: 0 0 40px;
  }
  
  @media (max-width: 767px){
    flex-direction: column;
    div {
      display: none;
    }
    input {
      margin: 0 !important;
      margin-bottom: 20px !important;
    }
  }
`

const Center = styled.div`
  display: flex;
  justify-content: center;
`

export const ContactForm = ({setSubmitted}) => {
  const nameInput = useRef();
  const emailInput = useRef();
  const phonenumberInput = useRef();
  const restaurantInput = useRef();

  const submitForm = (ev) => {
      fetch(`https://7oj07qp452.execute-api.eu-central-1.amazonaws.com/v1/mainorder-contact-form`, {
        method: "post",
        body: JSON.stringify({
          name: nameInput.current.value,
          email: emailInput.current.value,
          phonenumber: phonenumberInput.current.value,
          restaurant: restaurantInput.current.value
        }),
      }).then(r => {
        console.log(r.data);
        setSubmitted(true);
      });
      ev.preventDefault();
      ev.stopPropagation();
      return false;
  };

  return (
    <div>
      <form action={"https://7oj07qp452.execute-api.eu-central-1.amazonaws.com/v1//mainorder-contact-form"}>
        <InputGroup>
          <input ref={nameInput}
            type={"text"}
            required
            name="name"
            placeholder={"Vorname, Nachname"}
          />
          <div style={{ width: "200px" }}>&nbsp;</div>
          <input ref={restaurantInput}
            type={"text"}
            required
            name="restaurant"
            placeholder={"Restaurant / Café"}
          />
        </InputGroup>
        <InputGroup>
          <input ref={emailInput} type={"text"} name="email" placeholder={"E-mail"} />
          <div style={{ width: "200px", textAlign: "center" }}>oder</div>
          <input ref={phonenumberInput}
            type={"text"}
            name={"phonenumber"}
            placeholder={"Telefon-Nr."}
          />
        </InputGroup>
        <Center>
          <Button onClick={submitForm} type={"button"}>ABSENDEN</Button>
        </Center>
      </form>
    </div>
  )
}
