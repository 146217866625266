import React, {useState} from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { ContactForm } from "../components/contactForm"
import CheckCircleIcon from "../images/check-circle-icon.svg";
import {Pricing} from "../components/pricing";
export const Section = styled.div`
  max-width: 940px;
  margin: 0 auto;
  padding: 40px 0;
  font-size: 18px;
   @media (max-width: 991px) {
    padding: 40px;
    
  }
`;

export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  p { font-size: 20px; }
  svg {
    margin: 60px 0;
  }
`;

const ContactPage = () => {
  const [submitted, setSubmitted] = useState(false);
  const images = useStaticQuery(graphql`
    query {
      header: file(relativePath: { eq: "contact-background.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout
      smallHeader={true}
      title={"Die App ist bald fertig!"}
      image={images.header.childImageSharp.fluid}
    >
      <Section>
       <h1></h1>
        <h3>Schaut doch solange vorbei unter <a href={"https://www.deep-fat.com/menus"}>https://www.deep-fat.com/menus</a></h3>
      </Section>
    </Layout>
  )
}

export default ContactPage
